import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const DemandCallToAction = ({value, action}) => (
	<div>
        <div id="demand-banner">
            <p className="call-to-action hero__message">
                <a className="page-head__link page-head__link--glitch" title="En savoir plus" target="_blank"
                    data-glitch-text={value} href={action} style={{
                        borderRadius: "50px"
                        }}>
                    {value}
                </a>
            </p>
        </div>

        <div id="sticky-banner">
            <p className="call-to-action hero__message">
                <a className="call-to-action__link page-head__link--glitch" title="En savoir plus" target="_blank"
                    data-glitch-text={value} href={action} style={{
                        borderRadius: "50px"
                        }}>
                    {value}
                </a>
            </p>
        </div>
    </div>
)

export default DemandCallToAction
