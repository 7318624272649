import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
import Layout from "../components/layout"
import VideoCover from "../components/video-cover"
import DemandCallToAction from "../components/demand-call-to-action"
import SEO from "../components/seo"
import WhoAreWe from "../components/who-are-we"
import Sponsores from "../components/sponsores"

class TransformationInnovation extends React.Component {
  render = () => (
    
      <Layout>
        <SEO title="Transformation digitale" keywords={[
          "+formation +intelligence +artificielle","artificial intelligence","intelligence artificielle france","artificielle intelligence","intelligence artificielle ia","intelligence artificielle","expert intelligence artificielle","une intelligence artificielle","application intelligence artificielle","ai","robot intelligence artificiel","machine learning","intelligence","ia","ai intelligence","artifical intelligence","ai intelligent","artificial inteligence","ai technology","intelligence articielle","artificial artificial intelligence","ai platform","machine intelligence","ai artificial intelligence","ai and machine learning"
        ]} />
        <main className="scroll-jacker">

        <VideoCover
          title="Transformation digitale"
          videoUrl="/video/digitale.mp4"
        />

        <main className="manifest">
          <section className="manifest__section manifest__section--services">
            <header className="manifest__services-header manifest__services-header--toggled"></header>
            <article className="manifest__article">
              <h2 className="manifest__article-heading">Transformation digitale</h2>
              <h2 className="manifest__article-sub-heading">Rendez-vous à l’ère du numérique</h2>
              <p className="manifest__para">
                « Ne pas avancer, c’est reculer ». Face à un marché mouvant, l’entreprise doit sans cesse démontrer son dynamisme et sa capacité d’innovation et d’adaptation. Dans ce passage obligatoire qu’est la transformation digitale, Utopie vous accompagne de manière profonde et accélérée.
              </p>
              <p className="manifest__para">
                <strong>
                  Industrie 4.0, cloud, mobilité, internet des objets, big data... Ces termes vous sont familiers mais vous avez du mal à en cerner l’usage ? Osez en maîtriser la substance !
                </strong>
              </p>
              <p className="manifest__para">
                Levier déterminant de compétitivité, le digital est source de bénéfices réels et palpables pour votre entreprise. Porteur de nouveaux modes de fonctionnement plus collaboratifs, il élimine les frontières spatio-temporelles et favorise l’intelligence collective. Il rapproche les collaborateurs, facilite le partage de compétences, privilégie la collaboration transverse entre les services et encourage la polyvalence.
              </p>
              <p className="manifest__para">
                Avec les équipes d’Utopie à vos côtés, la culture numérique n’aura plus aucun secret pour vous. Nous mettons à votre disposition l’expertise de nos consultants et vous garantissons un accompagnement sur mesure.
              </p>
              <p className="manifest__para">
                <strong>
                Nous disposons de l’expérience, de la maîtrise et de la passion nécessaires pour mener à bien ce grand projet de transformation au sein de votre entreprise, sans rien laisser au hasard.
                </strong>
              </p>

              <p className="manifest__para">
                <br /> <br />
              </p>

            </article>

          </section>


        </main>
        
        </main>

        <Sponsores />
        <WhoAreWe />

      </Layout>
    
  )

  componentDidMount = () => { 
  }

}

export default TransformationInnovation
